import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Title from '../Title/Title';
import Video from '../Video';

const Paragraphs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  div {
    background: antiquewhite;
    :first-child {
      border: 1px solid red;
    }
    p {
      color: navy;
      &.classy-paragraph {
        color: brown;
      }
      &#unique-paragraph {
        background: aliceblue;
      }
    }
  }
`;

const InfoOferta = () => {
  return (
    <>
      <section id="cien_galeria">
        <section id="galeria">
          <Title title="Oferta" />
        </section>
      </section>
      <Container className="p-5">
        <div className="row">
          <div className="col-sm-6">
            <h1 className="text-left p-3">Sodowanie</h1>
            <p className="text-left">
              Sodowanie Sodowanie - to nowoczesna ekologiczna metoda oczyszczania różnych
              powierzchni wypierająca coraz częściej tradycyjną metodę za pomocą piaskowania.
              Oczyszczanie powierzchni sodą oczyszczoną (sprawdź w którym miejscu masz wstawić ...
              więcej) jest skuteczne i bezinwazyjne dla czyszczonego przedmiotu a jej własności
              fizyczne pozwalają na zastosowanie wszędzie tam , gdzie szczególnie ważne jest
              środowisko naturalne i zdrowie człowieka. Stosowana soda oczyszczona jako ścierniwo
              jest całkowicie ekologiczna i rozpuszcza się we wodzie. Pozostałości po czyszczeniu
              np. części silnikowych, urządzeń hydraulicznych możemy po prostu umyć w temperaturze
              wody ok 20 st.Celsjusza. Wodorowęglan sodu ( soda) zatrzymuje rozwój bakterii i możemy
              go bezpiecznie stosować do czyszczenia urządzeń w przemyśle spożywczym. Przy sodowaniu
              nie następuje zjawisko "iskrzenia" w związku z tym, możemy stosować tą technologię
              czyszczenia w obszarach, gdzie istnieje zagrożenie wybuchu np. przemysł rafineryjny,
              paliwowy itp. Ważnym jest, że czyszczona powierzchnia tą techniką jest wolna od
              uszkodzeń spowodowanych czyszczeniem czyli: brak gładkiej powierzchni, zarysowania na
              skutek czyszczenia lub zmiana struktury materiału czyszczonego.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <Video
              videoSrcURL="https://www.youtube.com/embed/JwqeqFrouyM"
              videoTitle="BSS w akcji"
            />
          </div>
          <div className="col-md-6 p-3">
            <Video
              videoSrcURL="https://www.youtube.com/embed/TP_yqjU-8rI"
              videoTitle="BSS w akcji"
            />
          </div>
          <div className="col-md-6">
            <h1 className="text-left p-3">Szkiełkowanie</h1>
            <p className="text-left">
              Szkiełkowanie to odmiana obróbki strumieniowej ( metoda alternatywna do oczyszczania
              powierzchni za pomocą piaskowania), gdzie użytym ścierniwem jest szkło w formie
              mikrogranulek lub grysu (frakcjonowanej stłuczki szklanej) zależnie od oczekiwanych
              efektów. Do obróbki elementów aluminiowych, gdzie zależy nam na gładkiej powierzchni
              stosujemy szkło w formie mikrogranulek. Do przygotowania przedmiotów do malowania,
              oczyszczamy powierzchnię przy zastosowaniu grysu szklanego w celu lepszej
              przyczepności powłok lakierniczych. Szkiełkowanie najczęściej wykorzystywane jest w
              motoryzacji do oczyszczania części samochodowych.
            </p>
          </div>
          <div className="col-md-6">
            <h1 className="text-left p-3">Piaskowanie</h1>
            <p className="text-left">
              Szkiełkowanie to odmiana obróbki strumieniowej ( metoda alternatywna do oczyszczania
              powierzchni za pomocą piaskowania), gdzie użytym ścierniwem jest szkło w formie
              mikrogranulek lub grysu (frakcjonowanej stłuczki szklanej) zależnie od oczekiwanych
              efektów. Do obróbki elementów aluminiowych, gdzie zależy nam na gładkiej powierzchni
              stosujemy szkło w formie mikrogranulek.</p>
              <p className="text-left">Do przygotowania przedmiotów do malowania,
              oczyszczamy powierzchnię przy zastosowaniu grysu szklanego w celu lepszej
              przyczepności powłok lakierniczych. Szkiełkowanie najczęściej wykorzystywane jest w
              motoryzacji do oczyszczania części samochodowych.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <Video
              videoSrcURL="https://www.youtube.com/embed/kzLIl9hqU2M"
              videoTitle="Piaskowanie dachu"
            />
          </div>
          <div className="col-xs-12">
            <Paragraphs><p className="text-left">
              Zalety nowoczesnej technologii Nie uszkadza oczyszczanej powierzchni Usuwa więcej
              rodzajów zabrudzeń (lakierów, farb, rdzy, zanieczyszczeń) Dokładniej doczyszcza
              powierzchnię, nie pozostawiając przebarwień Efektywniej rozpędza czyściwo,
              zmniejszając jego zużycie, a to zwiększa zysk Łatwe w uprzątnięciu, bo soda
              oczyszczona łatwo rozpuszcza się w wodzie Niższe zużycie materiału to także mniejsze
              zapylenie w trakcie pracy Preferowane przez Unię Europejską jako przyszła
              proekologiczna technologia Nie wywołuje pylicy płucnej u osób przebywających w miejscu
              pracy i otoczeniu</p>
            </Paragraphs>
          </div>
        </div>
      </Container>
    </>
  );
};

export default InfoOferta;
