import React, { useState, useEffect } from 'react';
import InfoOferta from './Oferta/Oferta';
import Footer from './Footer/Footer';
import Nav from './Nav/Nav';


import { PortfolioProvider } from '../context/context';

import { projectsData,  footerData, logoData } from '../mock/data';

function Oferta() {
  const [projects, setProjects] = useState([]);
  const [footer, setFooter] = useState({});
  const [logo, setLogo] = useState({});

  useEffect(() => {
    setLogo({ ...logoData });
    setProjects([...projectsData]);
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ logo, projects, footer }}>
      <Nav />
      <InfoOferta/>
      <Footer />
    </PortfolioProvider>
  );
}

export default Oferta;
